<template>
  <div class="row m-2 insurance-banner">
    <div class="col-6 ">
      <h4 class="item-primary-title pb-1">Insurance</h4>
      <p class="item-content">
        Reach out to your Relationship Manager to find out about our Insurance products
      </p>
      <button class="btn btn-booster-secondary" @click="redirectToAdviceCentre()">
        Back to Advice centre
      </button>
    </div>
    <div class="col-2"></div>
    <div class="col-4 ">
      <img class="umbrella-img" src="@/assets/yellow-umbrella.svg" />
    </div>
  </div>
</template>
  
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ErrorBanner",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      advicerCenterUrl: process.env.VUE_APP_ADVISER_CENTER_URL,
    };
  },
  mounted() {
    console.error(this.message);
  },
  methods: {
    redirectToAdviceCentre() {
      window.location.href = this.advicerCenterUrl;
    },
  },
});
</script>
  
<style lang="scss" scoped>
.insurance-banner {
  padding-top: 6rem;
  height: 19rem;
}

.item-content {
  font-family: "Nunito Sans";
  font-weight: 400;
  color: #4d4d4d;
  font-size: 1rem;
  line-height: 1.194rem;
}

.item-secondary-title {
  font-family: "Nunito Sans";
  font-weight: 700;
  color: #4d4d4d;
  font-size: 1.125rem;
  line-height: 1.534rem;
}

.umbrella-img {
  height: 14.5rem;
  width: 15.625rem;
  margin-top: -65px;
  display: flex;
}

.btn {
  width: 16.625rem;
  height: 2.25rem;
  background-color: transparent;
  margin-top: 7.5rem;
}
</style>