import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { authGuard, useAuth0 } from "@auth0/auth0-vue";
import { userStore } from "@/stores/UserStore";
import { AppClaims, AppRoles } from "@/enums/ApplicationClaims.enum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/products/insurance",
    beforeEnter: [authGuard, checkRoleAuthGuard],
    // HomeView page will be brought back in later releases
    // component: () =>
    //   import("@/pages/HomeView.vue"),
  },
  {
    path: "/products/insurance",
    name: "insurance-product",
    beforeEnter: [authGuard, checkRoleAuthGuard],
    component: () => import("@/pages/products/insurance/InsuranceHome.vue"),
    children: [
      {
        path: "",
        name: "insurance-home-default",
        component: () =>
          import("@/pages/products/insurance/insurance-home-default/InsuranceHomeDefault.vue"),
      },
      {
        path: "quote-and-apply",
        name: "insurance-quote-and-apply",
        component: () => import("@/pages/products/insurance/quote-and-apply/QuoteAndApply.vue"),
      },
      {
        path: "quote-and-apply/application",
        name: "insurance-application",
        component: () => import("@/pages/products/insurance/application/ApplicantDetailsCheck.vue"),
      },
      {
        path: "applications/:id",
        name: "applications-details",
        component: () =>
          import("@/pages/products/insurance/policies-and-application/ApplicationDetails.vue"),
      },
      {
        path: "policies/:id",
        name: "policies-details",
        component: () =>
          import("@/pages/products/insurance/policies-and-application/PolicyDetails.vue"),
      },
    ],
  },
  {
    path: "/clients",
    name: "clients",
    beforeEnter: [authGuard, checkRoleAuthGuard],
    component: () => import("@/pages/AdviserHubClients.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// Work around to fix the auth0 lib redirection loosing base url issue
router.beforeEach(() => {
  if (!window.location.href.includes(process.env.BASE_URL) && process.env.BASE_URL.length > 1) {
    window.location.href = window.location.origin + process.env.BASE_URL + "#/";
  }
});

function checkRoleAuthGuard() {
  const userInfo = userStore();

  const authService = useAuth0();
  const user = authService.user.value;

  try {
    if (!user || user["https://booster.co.nz/claims"] === undefined) {
      userInfo.showError("Access denied. User has no permission access to this application.");
      return false;
    }

    // Get all roles from the claims
    const adviserHubRoles = user["https://booster.co.nz/claims"]
      .filter((x: any) => x.key === AppClaims.AppRolesClaim)
      .flatMap((x: any) => x.value.split(","));

    // Check if any role matches the required one
    if (!adviserHubRoles.length || !adviserHubRoles.includes(AppRoles.InsuranceAdviser)) {
      userInfo.showError("Access denied. Your role does not permit access to this application.");
      return false;
    }

    const adviserExternalReference = user["https://booster.co.nz/claims"].find(
      (x: any) => x.key == AppClaims.AppExternalRefClaim
    );

    if (!adviserExternalReference || !adviserExternalReference.value) {
      userInfo.showError("Access denied. ExternalReference/AdviserId is missing.");
      return false;
    }

    const currentAdviserId = Number(adviserExternalReference.value);

    const adviserName = user["https://booster.co.nz/claims"].find(
      (x: any) => x.key == "display_name"
    ).value;

    userInfo.setAdviserId(currentAdviserId);

    userInfo.setUserName(adviserName);
  } catch (error) {
    userInfo.showError("An error occurred while checking your access. Please try again later.");
    return Error("Error:" + error);
  }

  return true;
}

export default router;
