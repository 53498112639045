<template>
  <div class="container">
    <div class="text-center">
      <img class="spinner-icon pb-2" src="@/assets/ajax-loader.gif" />
      <span class="item-label d-block">Loading...</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: 'LoadingSpinner',
})
</script>

<style lang="scss" scoped>
.spinner-icon {
  width: 1.5rem;
}
</style>
