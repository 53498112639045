<template>
  <div
    class="bg-white position-absolute loading-background d-flex justify-content-center align-items-center">
    <LoadingSpinner class="loading-spinner-z-index" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue'

export default defineComponent({
  name: "LoadingSpinnerWithBackground",
  components: {
    LoadingSpinner
  }
})
</script>

<style lang="scss" scoped>
.loading-spinner-z-index {
  z-index: 1055
}
</style>
