<template>
  <AdviserHubLayout />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AdviserHubLayout from "@/layouts/adviser-hub-layout/AdviserHubLayout.vue";
import RequestBuilder from "./rest-client/RequestBuilder";

export default defineComponent({
  name: "App",
  components: {
    AdviserHubLayout,
  },
  beforeMount() {
    if (this.$auth0.isAuthenticated) {
      RequestBuilder.interceptorSetup(this.$auth0);
    }
  },
});
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@/scss/shared-styles.scss";
</style>
